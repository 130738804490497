:root {
  --primary-color: black;
  --secondary-color: #ffffff;
  --tertiary-color: #ffcc33;
  --main-font: Exo, sans-serif;
}

div.page-contents {
  text-align: center;
  color: var(--primary-color);
  font-family: var(--main-font);
}

h4 {
  text-decoration: underline;
}

p {
  text-align: left;
  font-style: italic;
  font-size: 13px;
}

.disclaimers-mascot {
  max-width: 10%;
}
